<template>
  <div class="table-responsive mt-5">
    <router-link :to="{ name: 'contract_scontract_list' }">
      <el-button size="mini">回到合同列表</el-button>
    </router-link>
    <div class="text-center">
      <h3>收入合同修改</h3>
    </div>
    <table class="table table-bordered table-sm dark">
      <tbody>
        <tr>
          <td colspan="8" style="background-color: rgba(152, 150, 245, 0.05)">
            基本信息
          </td>
        </tr>
        <tr>
          <td class="text-center align-middle"><small>签订日期</small></td>
          <td>
            <el-date-picker
              v-model="formdata.contract_signt_time"
              type="date"
              placeholder=""
              size="small"
              format="YYYY/MM/DD"
              value-format="YYYY-MM-DD"
            ></el-date-picker>
          </td>
          <td class="text-center align-middle"><small>合同编号</small></td>
          <td>
            <el-input
              v-model="formdata.contract_num"
              placeholder=""
              size="small"
            />
          </td>
          <td class="text-center align-middle"><small>合同名称</small></td>
          <td>
            <el-input
              v-model="formdata.contract_name"
              placeholder=""
              size="small"
            />
          </td>
        </tr>
        <tr>
          <td class="text-center align-middle"><small>合同金额</small></td>
          <td>
            <el-input
              v-model="formdata.contract_amount"
              placeholder=""
              size="small"
            />
          </td>
          <td class="text-center align-middle"><small>发票种类</small></td>
          <td>
            <el-select
              v-model="formdata.tax_class"
              placeholder="Select"
              style="width: 240px"
            >
              <el-option key="1" label="普票" :value="1" />
              <el-option key="2" label="专票" :value="2" />
            </el-select>
          </td>
          <td class="text-center align-middle"><small>税率</small></td>
          <td>
            <el-select
              v-model="formdata.tax_rate"
              placeholder="Select"
              style="width: 240px"
            >
              <el-option key="1" label="1%" :value="1" />
              <el-option key="3" label="3%" :value="3" />
              <el-option key="5" label="5%" :value="5" />
              <el-option key="6" label="6%" :value="6" />
              <el-option key="9" label="9%" :value="9" />
              <el-option key="11" label="11%" :value="11" />
              <el-option key="13" label="13%" :value="13" />
              <el-option key="0" label="0%" :value="0" />
            </el-select>
          </td>
        </tr>
        <tr>
          <td class="text-center align-middle"><small>甲方单位</small></td>
          <td>
            <el-input v-model="formdata.partya" placeholder="" size="small" />
          </td>
          <td class="text-right align-middle"><small>乙方单位</small></td>
          <td>
            <el-input v-model="formdata.partyb" placeholder="" size="small" />
          </td>
          <td class="text-center align-middle"><small>合同类别</small></td>
          <td>
            <el-select
              v-model="formdata.scontractclass"
              placeholder="Select"
              style="width: 240px"
            >
              <el-option key="1" label="主合同" :value="1" />
              <el-option key="2" label="变更项" :value="2" />
              <el-option key="3" label="核减项" :value="3" />
            </el-select>
          </td>
        </tr>
        <tr>
          <td class="text-center align-middle"><small>预算值</small></td>
          <td>
            <el-input v-model="formdata.budget" placeholder="" size="small" />
          </td>
          <td class="text-right align-middle"></td>
          <td></td>
          <td class="text-center align-middle"></td>
          <td></td>
        </tr>
        <tr>
          <td>收款条件</td>
          <td colspan="7">
            <el-input
              v-model="formdata.contract_condition"
              :rows="4"
              type="textarea"
              placeholder=""
            />
          </td>
        </tr>
        <tr>
          <td>主要条款</td>
          <td colspan="7">
            <el-input
              v-model="formdata.contract_main"
              :rows="4"
              type="textarea"
              placeholder=""
            />
          </td>
        </tr>
        <tr>
          <td>备注</td>
          <td colspan="7">
            <el-input
              v-model="formdata.contract_text"
              :rows="4"
              type="textarea"
              placeholder=""
            />
          </td>
        </tr>
      </tbody>
    </table>
    <!-- 确认新增合同弹框 -->
    <el-popconfirm
      title="您确定要新增合同吗？"
      confirm-button-text="确定"
      cancel-button-text="取消"
      confirm-button-type="primary"
      @confirm="addcontract"
    >
      <template #reference>
        <el-button
          type="primary"
          size="mini"
          style="margin-left: 48%"
          :disabled="!issaveok"
          >保存新增</el-button
        >
      </template>
    </el-popconfirm>

    <div>
      <el-button :disabled="!issaveok" size="mini" @click="dialogVisible = true"
        >批量上传</el-button
      >

      <el-dialog v-model="dialogVisible" width="50%" @closed="getFileList">
        <div class="text-center">
          <file-upload
            :fileclass="fileclass"
            :prdclassid="prdclassid"
            urlpath="admin/AccessoryFile"
          ></file-upload>
        </div>
      </el-dialog>
      <el-table :data="tableData.data">
        <el-table-column prop="file_name" label="文件名称" width="300" />
        <el-table-column prop="create_time" label="上传时间" width="150" />
        <el-table-column label="操作" width="150" align="center">
          <template #default="scope">
            <el-button-group>
              <el-button
                size="small"
                plain
                @click="openPdfFile(scope.row.url_path)"
                >查看</el-button
              >
              <el-button
                size="small"
                plain
                type="danger"
                @click="deletePdfFile(scope.row.id)"
                >删除</el-button
              >
            </el-button-group>
          </template>
        </el-table-column>
      </el-table>
    </div>
  </div>

  <!-- 查看PDF文件 -->
  <el-dialog
    v-model="pdfurlVisible"
    width="100%"
    fullscreen
    @closed="pdfurl = ''"
  >
    <div class="text-center" style="height: 2000px">
      <iframe :src="pdfurl" width="100%" height="100%"></iframe>
    </div>
  </el-dialog>
</template>

<script>
import { ElMessageBox } from "element-plus";
import axios from "axios";
export default {
  data() {
    return {
      formdata: {
        contract_num: "", //合同编号
        contract_signt_time: "", // 合同签订时间
        contract_amount: 0, //合同金额
        contract_text: "", //备注
        contract_condition: "", //付款条件
        contract_main: "", //主要条款
        contract_name: "", //合同名称
        project_id: window.sessionStorage.getItem("project_ids"), //项目ID
        tax_class: "", //发票种类
        tax_rate: "", //税率
        partya: "", //甲方
        partyb: "", //乙方
        scontractclass: "", //合同种类
        budget: 0,
      },
      issaveok: true, //如果增加成功，则新增按钮失效
      //分类ID     0-项目图片  1-付款合同  2-付款申请   3-发票
      //           4-银行回单  5-收款合同  6-收款凭证  7-资金计划附件
      fileclass: "5",

      //记录ID
      prdclassid: window.sessionStorage.getItem("scontractrowid"), //新增记录ID

      supplier_name: "", //供应商名称

      dialogVisible: false, //是否打开上传模态框

      pdfurl: "", //PDF文件urlf地址
      pdfurlVisible: false, //查看显示PDF文件

      //附件列表数据
      tableData: {
        data: [],
      },
    };
  },
  created() {
    //加载单条记录
    this.loadtabledata(this.prdclassid);
    //获取对应记录的附件
    this.getFileList();
  },
  methods: {
    //新增付款合同
    addcontract() {
      axios
        .post(
          "admin/ScontractInfo/" + this.prdclassid + "/edit",
          this.formdata,
          {
            headers: {
              token: window.sessionStorage.getItem("token"),
            },
          }
        )
        // .then((response) => {
        .then((response) => {
          ElMessageBox.alert("修改成功！" + response, "提示", {
            confirmButtonText: "OK",
          });
        })
        //.catch((error) => {
        .catch(() => {
          ElMessageBox.alert("失败！", "提示", {
            confirmButtonText: "OK",
          });
        })
        .then(() => {
          // 总是会执行
        });
    },
    //获取对应记录的附件
    getFileList() {
      axios
        .get("admin/AccessoryFile", {
          params: {
            fileclass_id: this.fileclass,
            record_id: this.prdclassid,
          },
          headers: {
            token: window.sessionStorage.getItem("token"),
          },
        })
        .then((response) => {
          this.tableData.data = response.data.data;
        })
        .catch((error) => {
          console.log(error);
        })
        .then(() => {
          // 总是会执行
        });
    },
    //删除PDF文件 @click="deletePdfFile(scope.row.id)"
    deletePdfFile(rowid) {
      axios
        .delete("admin/AccessoryFile/" + rowid, {
          params: {},
          headers: {
            token: window.sessionStorage.getItem("token"),
          },
        })
        .then((response) => {
          console.log("******11", response.data.data, "11******");
          this.getFileList();
        })
        .catch((error) => {
          console.log(error);
        })
        .then(() => {
          // 总是会执行
        });
    },
    //查看PDf
    openPdfFile(url) {
      this.pdfurlVisible = true;
      this.pdfurl = url;
    },
    //加载单条记录
    loadtabledata(rowid) {
      axios
        .get("admin/ScontractInfo/" + rowid, {
          params: {},
          headers: {
            token: window.sessionStorage.getItem("token"),
          },
        })
        .then((response) => {
          console.log("!@单要记录赋值!@", response.data.data[0].id);
          //单要记录赋值
          if (response.data.data.length > 0) {
            this.formdata.contract_num = response.data.data[0].contract_num; //合同编号
            this.formdata.contract_signt_time =
              response.data.data[0].contract_signt_time; // 合同签订时间
            this.formdata.contract_amount =
              response.data.data[0].contract_amount; //合同金额
            this.formdata.contract_text = response.data.data[0].contract_text; //备注
            this.formdata.contract_condition =
              response.data.data[0].contract_condition; //付款条件
            this.formdata.contract_main = response.data.data[0].contract_main; //主要条款
            this.formdata.contract_name = response.data.data[0].contract_name; //合同名称
            this.formdata.tax_class = response.data.data[0].tax_class; //发票种类
            this.formdata.tax_rate = response.data.data[0].tax_rate; //税率
            this.formdata.partya = response.data.data[0].partya; //甲方
            this.formdata.partyb = response.data.data[0].partyb; //乙方
            this.formdata.scontractclass = response.data.data[0].scontractclass; //合同种类
            this.formdata.budget = response.data.data[0].budget;
          }
        })
        .catch((error) => {
          console.log(error);
        })
        .then(() => {
          // 总是会执行
        });
    },
  },
};
</script>

<style></style>
